import React from "react";

import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardText,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Label
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faFilter, 
    faMapMarkerAlt, 
    faHomeAlt, 
    faCompass, 
    faLocationDot, 
    faChevronLeft, 
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faInfo,
    faPenRuler,
    faEraser,
    faCrown
} from '@fortawesome/free-solid-svg-icons';
import { 
    GoogleMap, 
    InfoWindow, 
    Marker, 
    Polygon, 
    StandaloneSearchBox,
    useJsApiLoader,
} from '@react-google-maps/api';
import Pagination from "./pagination/Pagination";
import Navbars from "routers/navbars";
import { ErrorAlert } from "components/alert";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import LoginModal from "components/loginModal";
import LoadingIndicretor from "./components/loading";
import HouseMarker from 'assets/img/house.svg';
import LandMarker from 'assets/img/land.svg';
import CommercialMarker from 'assets/img/commercial.svg';
import HotelMarker from 'assets/img/hotel.svg';
import FactoryMarker from 'assets/img/factory.svg';
import MoreMarker from 'assets/img/more.svg';
import Favorite from "components/favorites";
import circle_point from 'assets/img/Geo-1.svg';
import { LoginContext } from "components/loginContext/LoginContext";
import MeasureTool from 'measuretool-googlemaps-v3';

const path_url = process.env.REACT_APP_API_CONNECT;
const google_api = process.env.REACT_APP_GOOGLE_API;
// const longdo_api = process.env.REACT_APP_LONGDO_MAP;
const libraries = ['places'];

function Map(){
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: google_api,
        region:'th',
        language: 'th',
        version: "weekly",
        libraries: libraries,
    });
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(8);
    const [typeSelect, setTypeSelect] = React.useState(0);
    const [fullMap, setFullMap] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [coordinateCenter, setCoordinateCenter] = React.useState({
        lat: 18.737666197841595,
        lng: 98.98034537929969,
    })
    const [coordinateUser, setCoordinateUser] = React.useState({
        lat: 18.737666197841595,
        lng: 98.98034537929969,
    })
    // const [coordinates, setCoordinates] = React.useState([]);
    const [loadingVisible, setLoadingVisible] = React.useState(false);
    const [EstateFetch, setEstateFetch] = React.useState([]);
    const [EststeActive, setEstateActive] = React.useState(null);
    const [EstateHover, setEstateHover] = React.useState(null);
    const [searchParam, setSearchParam] = React.useState({
        province: "",
        size: {
            min: {
                count: 0,
                unit: ''
            },
            max:{
                count: 0,
                unit: ''
            }
        },
        price:{
            min: 0,
            max: 0
        },
    })
    const {userData, setUserData} = React.useContext(LoginContext);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [map, setMap] = React.useState(null);

    const toggle = () => setModal(!modal);
    const google = window.google;
    const mapRef = React.useRef(null);
    const searchBox = React.useRef(null);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const province = query.get('province');
    const size = JSON.parse(query.get('size'));
    const price = JSON.parse(query.get('price'));

    React.useEffect(() => {
        // console.log(window.location.hostname)
        if(province){
            setSearchParam((prev) => {
                return{...prev, province: province ? province : ""}
            })
        }else{
            navigator.geolocation.getCurrentPosition(function(position){
                const {latitude, longitude} = position.coords;
                setCoordinateCenter({lat:latitude, lng:longitude});
                setCoordinateUser({lat:latitude, lng:longitude})
                FetchDataBySale({lat:latitude, lng:longitude});
            },(err)=>{
                if(err){
                    FetchDataBySale();
                    ErrorAlert({text:'กรุณาตรวจสอบคำขออนุญาตการเปิดใช้งานที่ตั้งของท่าน'})
                }
            })
        }
        if(size){
            setSearchParam((prev) => {
                return{
                    ...prev, 
                    size: {
                        min: {
                            count: size.min.count ? size.min.count : 0,
                            unit: size.min.unit ? size.min.unit : '',
                        },
                        max: {
                            count: size.max.count ? size.max.count : 0,
                            unit: size.max.unit ? size.max.unit : '',
                        }
                    }
                }
            })
        }

        if(price) setSearchParam((prev) =>{
            return{
                ...prev,
                price: {
                    min: price.min ? price.min : 0,
                    max: price.max ? price.max : 0,
                }
            }
        })
        

    },[])

    // React.useCallback(() =>{
    //     // const user = localStorage.getItem('profile');
    //     // const pareUser = JSON.parse(user);
    //     // setUser(pareUser)
        // var el = document.getElementById("clickInfo")
        // el.addEventListener('click', function(){ 
        //     clickInfo()
        // })
    // },[])

    const onMapLoad = (map) =>{
        if(searchParam.province){
            let request = {
                query: "แม่จัน,"+searchParam.province,
                fields: ["name", "geometry"]
              };
    
            let service = new google.maps.places.PlacesService(map);
            service.findPlaceFromQuery(request, (results, status) =>{
                if(status === google.maps.places.PlacesServiceStatus.OK){
                    const dd = results[0].geometry.location
                    setCoordinateCenter({lat: dd.lat(), lng: dd.lng()})
                    FetchDataBySale({lat: dd.lat(), lng: dd.lng()})
                }
            })
        }
    }
    
    function FetchDataBySale({lat=18.737666197841595, lng=98.98034537929969}){
        setLoadingVisible(true);
        
        axios.post(path_url+'/sale/getCoordinates',{
            latitude: lat,
            longitude: lng,
            type: typeSelect
        })
        .then((res) =>{
            console.log(res.data)
            const data = Array.from(res.data)
            .filter(item => {
                if(size && size.min.count && size.max.count){
                    return item?.owner?.size[size.min.unit] > parseFloat(size.min.count) && item?.owner?.size[size.max.unit] < parseFloat(size.max.count)
                }else{
                    return item
                }
            })
            .filter(item => {
                if(price && price.min && price.max){
                    return item?.owner?.totalPrice > price.min && item?.owner?.totalPrice < price.max
                }else{
                    return item
                }
            })
            setEstateFetch(data);
            setLoadingVisible(false);
            
        })
        .catch(err =>{
            console.log(err)
        })
    }

    // React.useEffect(() => {
    //     function clickInfo(){
    //         console.log("cvxcvxc")
    //     }

    //     const el = document.getElementById("clickInfo")
    //     el.addEventListener("click", clickInfo)
    // },[])

    const EstateWithFilter = EstateFetch?.filter((items) =>{
        if(typeSelect === 1){
            return items.owner.saleCategory === "LAND"
        }else if(typeSelect === 2){
            return items.owner.saleCategory === "HOME"
        }else{
            return items
        }
    })
    .filter(item => {
        if(searchParam.size.min.count >= 0 && searchParam.size.max.count > 0){
            return item?.owner?.size[searchParam.size.min.unit] > parseFloat(searchParam.size.min.count) && item?.owner?.size[searchParam.size.max.unit] < parseFloat(searchParam.size.max.count)
        }else{
            return item
        }
    })
    .filter(items =>{
        if(searchParam.price.min >=0 && searchParam.price.max > 0){
            return items?.owner?.totalPrice > searchParam.price.min && items?.owner?.totalPrice < searchParam.price.max
        }else{
            return items
        }
    })

    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const dataFetch = EstateWithFilter?.slice(firstPageIndex, lastPageIndex);
    const widthScreen = window.innerWidth;

    React.useEffect(() =>{
        if(widthScreen <= 991){
            setFullMap(true)
        }
    },[])

    const dragEnd = React.useCallback(function callback(){
        const currentLocation = mapRef?.current?.getCenter()?.toJSON();
        FetchDataBySale({lat: currentLocation.lat, lng: currentLocation.lng})
        setCurrentPage(1)
    },[])

    const onLoad = React.useCallback(function callback(val){
        mapRef.current = val;
        setMap(val)
    },[])

    const onUnmount = React.useCallback(function callback(map) {
        setCoordinateCenter(null)
    }, [])

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const parePrice = (val) =>{
        let thaibath = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return thaibath;
    }

    const router = useHistory();
    const handleActiveMarker = (val) =>{
        router.push(`/Map?sale=${val._id}`)
        setEstateActive(val)
    }
    
    const onLoadSearchbox = ref => searchBox.current = ref;
    const onPlacesChanged = () => {
        const dd = searchBox?.current?.getPlaces()[0].geometry.location
        setCoordinateCenter({lat: dd.lat(), lng: dd.lng()})
        FetchDataBySale({lat: dd.lat(), lng: dd.lng()})
    };

    const onSelectType = (val) =>{
        setTypeSelect(val)
    }

    const toEstateDetail = (val) =>{
        window.open(`/estate_detail/${val}`)
        // router.push(`/EstateDetail/?sale=${val}`)
    }

    // const searchFilter = () =>{
    //     const datafilter = [...EstateFilter]
    //     const dataResult = datafilter.filter(item =>{
    //         if(typeSelect === 1){
    //             return item.owner.saleCategory === "LAND"
    //         }else if(typeSelect === 2){
    //             return item.owner.saleCategory === "HOME"
    //         }else{
    //             return item
    //         }
    //     })
    //     .filter(item => {
    //         if(searchParam.size.min.count && searchParam.size.max.count){
    //             return item?.owner?.size[searchParam.size.min.unit] > parseFloat(searchParam.size.min.count) && item?.owner?.size[searchParam.size.max.unit] < parseFloat(searchParam.size.max.count)
    //         }else{
    //             return item
    //         }
    //     })
    //     .filter(item => {
    //         if(searchParam.price.min && searchParam.price.max){
    //             return item?.owner?.totalPrice > searchParam.price.min && item?.owner?.totalPrice < searchParam.price.max
    //         }else{
    //             return item
    //         }
    //     })
    //     setEstateFetch(dataResult)
    //     toggle()
    // }

    function onChangeSize(val, prop, child){
        const data = {...searchParam}
        data['size'][prop][child] = val
        setSearchParam({...data})
    }

    function onChangePrice(val, prop){
        const data = {...searchParam}
        data['price'][prop] = val
        setSearchParam({...data})
    }

    const checkFavorite = async (id) =>{
        if(userData !== null){
            return userData.favorites.some((item) => item === id)
        }else{
            return null
        }
    }

    async function onFavorite(id){
        const fa = await checkFavorite(id)
        if(fa === false){
            try{
                const data = {...userData}
                const favo = [...data.favorites, id]
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: favo
                })

                if(res.status === 200){
                    data['favorites'] = favo
                    // localStorage.setItem('profile', JSON.stringify({...data}));
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else if(fa === true){
            try{
                const data = {...userData};
                const favo = [...data.favorites];
                const dataFilter = favo.filter((item) => item !== id)
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: dataFilter
                })

                if(res.status === 200){
                    data['favorites'] = dataFilter
                    // localStorage.setItem('profile', JSON.stringify({...data}));
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else{
            setLoginOpen(true)
        }
    }

    var measure;
    function measureToolClick(){

        measure = new MeasureTool(map,{
            contextMenu: false,
            unit: MeasureTool.UnitTypeId.METRIC,
        })

        measure.addListener('measure_start', () => {
            console.log('started');
        });

        measure.start()
    }

    function measureToolEnd(){
        measure.end()
    }

    return(
        <>
        <Navbars />
        <div className="map-container">
            <div className={fullMap ? "map-wrapper fullmap" : "map-wrapper"}>
                <div className="map-wrapper-1">
                    <LoadingIndicretor 
                    visible={loadingVisible} 
                    className='loading'
                    alt="loading"
                    />
                    <Row className={fullMap ? "row-cols-1 row-cols-md-2 wrapper-body full" : "row-cols-1 row-cols-md-2 wrapper-body"}>
                        {dataFetch?.map((item,i) =>{
                            return(
                                <Col 
                                key={i} 
                                style={{marginBottom: 20}}
                                >
                                    <Card 
                                    style={{cursor: item?.owner?.status === 'SOLD' ? 'unset' : 'pointer'}} 
                                    onMouseOver={() => setEstateHover({id:item?._id})}
                                    onMouseLeave={() => setEstateHover(null)}
                                    >
                                        <div className="card-tag">
                                            <div className="tag">
                                                {item?.owner?.saleType === "OWNER" ?
                                                <div className="tag-1 bg_default color-white mb-10">
                                                    เจ้าของขายเอง
                                                </div>
                                                :
                                                null
                                                }
                                                {item?.owner?.quick ? 
                                                <div className="tag-2 bg_redpink color-white mb-10">
                                                    ขายด่วน
                                                </div> 
                                                : null}
                                                {item?.owner?.status === 'SOLD' ? 
                                                <div className="tag-3 bg_danger color-white">
                                                    ขายแล้ว
                                                </div> 
                                                : null}
                                            </div>
                                            <div className="tag-right">
                                                {item?.owner?.package?.type === "PERPLOT" || item?.owner?.user?.role === "PREMIUM" ? 
                                                <div className="tag-1 color-warning">
                                                    <FontAwesomeIcon icon={faCrown} style={{fontSize: 25}} />
                                                </div>
                                                :null}
                                            </div>
                                            <Favorite onFavorite={() => onFavorite(item?.owner?._id)} favorite={userData !== null ? userData.favorites.some((items) => items === item?.owner?._id) : false} />
                                            <CardImg 
                                            alt={item?.owner?.titleDeedNumber}
                                            src={item?.owner?.images[0]?.path} 
                                            onClick={() => item?.owner?.status === 'SOLD' ? null : toEstateDetail(item?.owner?._id)}
                                            />
                                        </div>
                                        <CardBody onClick={() => item?.owner?.status === 'SOLD' ? null : toEstateDetail(item?.owner?._id)}>
                                            <div className="body-1">
                                                <CardTitle>
                                                    {item?.owner?.title}
                                                </CardTitle>
                                                <CardText>{item?.owner?.description}</CardText>
                                            </div>
                                            {item?.owner?.status === 'SOLD' ? 
                                            <div className="body-2">
                                                <CardText>{intl.format(0)}</CardText>
                                                {item?.owner?.pricePerWa ?
                                                    <CardText className="color-default">{intl.format(0)}/ตร.ว.</CardText>
                                                :null}
                                            </div>
                                            :
                                            <div className="body-2">
                                                <CardText>{intl.format(item?.owner?.totalPrice)}</CardText>
                                                {item?.owner?.pricePerWa ?
                                                    <CardText className="color-default">{intl.format(item?.owner?.pricePerWa)}/ตร.ว.</CardText>
                                                :null}
                                            </div>}
                                            <CardText>
                                                <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                                {item?.owner?.saleDistrict + ", " + item?.owner?.saleProvince}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}
                        <div className="fixed">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={EstateWithFilter.length}
                                pageSize={pageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </Row>
                </div>
                <div className="map-wrapper-2">
                    {isLoaded ?
                        <GoogleMap
                        mapContainerClassName="mapWrapper"
                        center={coordinateCenter}
                        zoom={15}
                        onDragEnd={dragEnd}
                        onLoad={(val) => {
                            onLoad(val)
                            onMapLoad(val)
                        }}
                        onUnmount={onUnmount}
                        
                        options={{ 
                            gestureHandling: 'greedy', 
                            fullscreenControl: false,
                            mapTypeControl: true,
                            mapTypeControlOptions:{
                                position: google && google.maps.ControlPosition.LEFT_TOP,
                                style: google && google.maps.MapTypeControlStyle.DROPDOWN_MENU
                            },
                            // mapId:"cec75db59da49d87"
                        }}
                        onClick={() => setEstateActive(null)}
                        >
                            <LoadingIndicretor 
                            visible={loadingVisible} 
                            className='loading'
                            alt="loading"
                            />
                            <button className="btn-fullmap" onClick={() => setFullMap(!fullMap)}>
                                <FontAwesomeIcon 
                                icon={
                                    widthScreen >= 991 ? 
                                    fullMap ? faChevronRight : faChevronLeft : 
                                    fullMap ? faChevronUp : faChevronDown
                                } 
                                />
                            </button>
                            <StandaloneSearchBox
                            onLoad={onLoadSearchbox}
                            onPlacesChanged={onPlacesChanged}
                            >
                                <input
                                defaultValue={searchParam.province}
                                type="text"
                                placeholder="ค้นหาสถานที่"
                                style={{
                                    boxSizing: 'border-box',
                                    border: '1px solid transparent',
                                    width: 240,
                                    height: 32,
                                    padding: '0 12px',
                                    borderRadius: '3px',
                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                    fontSize: 14,
                                    outline: 'none',
                                    textOverflow: 'ellipses',
                                    position: "absolute",
                                    top: 10,
                                    left: "50%",
                                    marginLeft: '-120px'
                                }}
                                
                                />
                            </StandaloneSearchBox>
                            <Button 
                            color="white" 
                            className="btn-filter" 
                            onClick={toggle}
                            >
                                <FontAwesomeIcon icon={faFilter} className="icon" />
                            </Button>
                            <div
                            style={{
                                display:'flex', 
                                flexDirection:'column',
                                position:'absolute', 
                                right: 15,
                                bottom: 200,
                                justifyContent:'center',
                                alignItems:'center'
                            }}
                            >
                                <button
                                style={{
                                    marginTop: 7,
                                    marginBottom: 7,
                                    width: 35, 
                                    height: 35, 
                                    borderRadius: 25, 
                                    border: 'none', 
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    cursor: 'pointer'
                                }}
                                onClick={() => measureToolClick()}
                                >
                                    <FontAwesomeIcon icon={faPenRuler} />
                                </button>
                                <button
                                style={{
                                    marginTop: 7,
                                    marginBottom: 7,
                                    width: 35, 
                                    height: 35, 
                                    borderRadius: 25, 
                                    border: 'none', 
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    cursor: 'pointer'
                                }}
                                onClick={() => measureToolEnd()}
                                >
                                    <FontAwesomeIcon icon={faEraser} />
                                </button>
                            </div>
                            <div
                            style={{
                                position:'absolute', 
                                left: 15,
                                bottom: 30,
                                padding: '0px 5px',
                                backgroundColor: 'white'
                            }}
                            >
                                <ul>
                                    <li style={{listStyle:'inside', color: '#EF9751'}}>
                                        <small>ที่ดิน</small>
                                    </li>
                                    <li style={{listStyle:'inside', color: '#1789FE'}}>
                                        <small>บ้าน</small>
                                    </li>
                                    <li style={{listStyle:'inside', color: '#2EB82E'}}>
                                        <small>อาคารพาณิชย์</small>
                                    </li>
                                    <li style={{listStyle:'inside', color: '#FF4D4D'}}>
                                        <small>โรงแรม/รีสอร์ท</small>
                                    </li>
                                    <li style={{listStyle:'inside', color: '#884ea0'}}>
                                        <small>โรงงาน/โกดัง</small>
                                    </li>
                                    <li style={{listStyle:'inside', color: '#595959'}}>
                                        <small>อื่นๆ</small>
                                    </li>
                                </ul>
                            </div>
                            <Marker 
                            position={coordinateUser}
                            icon={circle_point}
                            />
                            {EstateWithFilter.map((items, i) =>{
                                return(
                                    <div key={i}>
                                    <Polygon
                                    options={{
                                        strokeColor: EstateHover?.id === items?._id || query.get('sale') === items?._id ? 'red' 
                                        :items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959',
                                        strokeWeight: 3,
                                        fillColor: EstateHover?.id === items?._id || query.get('sale') === items?._id ? 'red' 
                                        :items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959',
                                        strokeOpacity: EststeActive ? EststeActive._id === items?._id ? 1 : 0.6 : 1
                                    }}
                                    path={items?.paths?.coordinates[0].map(element =>{
                                        return{lat: element[1], lng: element[0]}
                                    })}
                                    onClick={() => handleActiveMarker(items)}
                                    />
                                    {items?.owner ? (
                                    <Marker
                                    
                                    icon={{
                                        url: items?.owner?.saleCategory === 'LAND' ? LandMarker 
                                        :items?.owner?.saleCategory === 'HOME' ? HouseMarker
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? CommercialMarker
                                        :items?.owner?.saleCategory === 'HOTEL' ? HotelMarker
                                        :items?.owner?.saleCategory === 'FACTORY' ? FactoryMarker
                                        :MoreMarker,
                                        scaledSize: items?.owner?.saleCategory === 'LAND' ? new google.maps.Size(150,49,'px','px') 
                                        :items?.owner?.saleCategory === 'HOME' ? new google.maps.Size(160,49,'px','px')
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? new google.maps.Size(155,49,'px','px')
                                        :items?.owner?.saleCategory === 'HOTEL' ? new google.maps.Size(160,49,'px','px')
                                        :items?.owner?.saleCategory === 'FACTORY' ? new google.maps.Size(160,49,'px','px')
                                        :new google.maps.Size(150,49,'px','px')
                                    }}
                                    label={{
                                        color: EstateHover?.id === items?._id || query.get('sale') === items?._id ? 'red' 
                                        :items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959',
                                        text: items?.owner?.status === 'SOLD' ? 'ขายแล้ว' 
                                        : items?.owner?.saleCategory === 'LAND' 
                                        ? `${intl.format(items?.owner?.pricePerWa)}` 
                                        : `${intl.format(items?.owner?.totalPrice)}`,
                                        fontFamily: "'Prompt', sans-serif"
                                    }}
                                    position={{
                                        lat: items?.centerPoint?.coordinates[1],
                                        lng: items?.centerPoint?.coordinates[0]
                                    }}
                                    opacity={EststeActive ? EststeActive._id === items?._id ? 1 : 0.6 : 1}
                                    onClick={() => handleActiveMarker(items)}
                                    >
                                        {EststeActive?._id === items?._id ? 
                                            <InfoWindow 
                                            onCloseClick={() => setEstateActive(null)}
                                            >
                                                <>
                                                
                                                <ImageGallery 
                                                showPlayButton={false}
                                                showThumbnails={false}
                                                showFullscreenButton={false}
                                                renderLeftNav={(onClick, disable) =>(
                                                    <Button 
                                                    type="button"
                                                    className="image-gallery-icon image-gallery-left-nav" 
                                                    disabled={disable}
                                                    onClick={onClick}
                                                    aria-label="Previous Slide"
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} onClick={onclick} className="gallery-icon-size" />
                                                    </Button>
                                                )}
                                                renderRightNav={(onClick, disable) =>(
                                                    <Button 
                                                    type="button"
                                                    className="image-gallery-icon image-gallery-right-nav" 
                                                    disabled={disable}
                                                    onClick={onClick}
                                                    aria-label="Next Slide"
                                                    >
                                                        <FontAwesomeIcon icon={faChevronRight} className="gallery-icon-size" />
                                                    </Button>
                                                )}
                                                items={EststeActive?.owner?.images.map((images) =>{
                                                    return{
                                                        original : images.path,
                                                        originalClass:"item-img",
                                                    }
                                                })}
                                                />
                                                {EststeActive?.owner?.status === 'SOLD' ? 
                                                <div style={{
                                                    display:'block',
                                                    position:'absolute',
                                                    top:20,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    color: 'white'
                                                }}
                                                className="bg_danger"
                                                >
                                                    ขายแล้ว
                                                </div> : null}
                                                <div style={{display:'flex', justifyContent:'end', alignItems:'center', paddingTop:10}}>
                                                    <Favorite 
                                                    onFavorite={() => onFavorite(EststeActive?.owner?._id)} 
                                                    favorite={userData !== null ? userData.favorites.some((items) => items === EststeActive?.owner?._id) : false} 
                                                    />
                                                    <Button color="favorite" className="ml-10" onClick={() => toEstateDetail(items?.owner?._id)}>
                                                        <FontAwesomeIcon icon={faInfo} />
                                                    </Button>
                                                </div>
                                                <div className="mt-20 mb-10" style={{borderBottom:'0.5px solid gray'}}>
                                                    <p 
                                                    className="font-size-sm font-weight-500 btn" 
                                                    onClick={() => toEstateDetail(items?.owner?._id)}
                                                    >
                                                        {EststeActive?.owner?.title}
                                                    </p>
                                                </div>
                                                <Row>
                                                    <Col className="mb-10">
                                                        <p className="font-size-sm color-gray">ขนาดแปลงที่ดิน</p>
                                                        <p className="font-size-sm">{EststeActive?.owner?.size.rai + " ไร่ " + EststeActive?.owner?.size.ngan + " งาน " + EststeActive?.owner?.size.wa + " วา"}</p>
                                                    </Col>
                                                </Row>
                                                {EststeActive?.owner?.status === 'SOLD' ? 
                                                <Row className="row-cols-2 pb-10 mt-20" style={{borderBottom:'0.5px solid gray'}}>
                                                    <Col>
                                                        <p className="font-size-sm color-gray">ราคารวม</p>
                                                        <p className="font-weight-500 color-default">{parePrice(0)} บาท</p>
                                                    </Col>
                                                    <Col>
                                                        <p className="font-size-sm color-gray">ราคาต่อตารางวา</p>
                                                        <p className="font-weight-500 color-default">{parePrice(0)} บาท</p>
                                                    </Col>
                                                </Row>
                                                : 
                                                <Row className="row-cols-2 pb-10 mt-20" style={{borderBottom:'0.5px solid gray'}}>
                                                    <Col>
                                                        <p className="font-size-sm color-gray">ราคารวม</p>
                                                        <p className="font-weight-500 color-default">{parePrice(EststeActive?.owner?.totalPrice)} บาท</p>
                                                    </Col>
                                                    {EststeActive?.owner?.pricePerWa ? 
                                                    <Col>
                                                        <p className="font-size-sm color-gray">ราคาต่อตารางวา</p>
                                                        <p className="font-weight-500 color-default">{parePrice(EststeActive?.owner?.pricePerWa)} บาท</p>
                                                    </Col>: null}
                                                </Row>
                                                }
                                                {EststeActive?.owner?.status !== 'SOLD' ? 
                                                <Row>
                                                    <Col className="col-12" style={{display:'flex', justifyContent:'center'}}>
                                                        <Button 
                                                        type="button" 
                                                        color="transparent" 
                                                        className="pb-10 pt-10"
                                                        onClick={() => toEstateDetail(items?.owner?._id)}
                                                        >
                                                            ดูรายละเอียด
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                : null}
                                                </>
                                            </InfoWindow>
                                        : null}
                                    </Marker>
                                    )
                                    : null}
                                    </div>
                                )
                            })
                            }
                        </GoogleMap>
                    : 'Map cannot be loaded right now, sorry.'}
                </div>
            </div>
        </div>
        {/* <Footer /> */}
        <Modal isOpen={modal} toggle={toggle}>
            {/* <ModalHeader toggle={toggle}>ค้นหาข้อมูล</ModalHeader> */}
            <ModalBody>
                <Form>
                    <FormGroup className="form-group">
                        <Label style={{fontWeight:400, fontSize:'large'}}>ช่วงขนาดที่ดิน/บ้าน</Label>
                        <Row className="row-cols-1 row-cols-md-2">
                            <Col>
                                <InputGroup>
                                    <Input 
                                    type="number"
                                    placeholder="ต่ำสุด" 
                                    value={searchParam.size.min.count} 
                                    onChange={(val) => onChangeSize(parseFloat(val.target.value), 'min', 'count')}
                                    />
                                    <select 
                                    className="form-select" 
                                    value={searchParam.size.min.unit}
                                    onChange={(val) => onChangeSize(val.target.value, 'min', 'unit')}
                                    >
                                        <option disabled value={''}>เลือก</option>
                                        <option className="color-darkblue" value={'wa'}>ตร.ว</option>
                                        <option className="color-darkblue" value={'ngan'}>งาน</option>
                                        <option className="color-darkblue" value={'rai'}>ไร่</option>
                                    </select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input 
                                    type="number"
                                    placeholder="สูงสุด" 
                                    value={searchParam.size.max.count} 
                                    onChange={(val) => onChangeSize(parseFloat(val.target.value), 'max', 'count')}
                                    />
                                    <select 
                                    className="form-select" 
                                    value={searchParam.size.max.unit}
                                    onChange={(val) => onChangeSize(val.target.value, 'max', 'unit')}
                                    >
                                        <option disabled value={''}>เลือก</option>
                                        <option className="color-darkblue" value={'wa'}>ตร.ว</option>
                                        <option className="color-darkblue" value={'ngan'}>งาน</option>
                                        <option className="color-darkblue" value={'rai'}>ไร่</option>
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label style={{fontWeight:400, fontSize:'large'}}>ช่วงราคาที่ดิน/บ้าน</Label>
                        <Row className="row-cols-1 row-cols-md-2">
                            <Col>
                                <InputGroup>
                                    <Input 
                                    placeholder="ต่ำสุด" 
                                    type="number" 
                                    value={searchParam.price.min} 
                                    onChange={(val) => onChangePrice(parseFloat(val.target.value), 'min')}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input 
                                    placeholder="สูงสุด" 
                                    type="number" 
                                    value={searchParam.price.max} 
                                    onChange={(val) => onChangePrice(parseFloat(val.target.value), 'max')}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
                <p className="header">ประเภทการแสดง</p>
                <div className="property-type-wrapper">
                    <Button className={typeSelect === 0 ? "nav-tabs active":"nav-tabs"} onClick={() => onSelectType(0)}>
                        <FontAwesomeIcon icon={faCompass} style={{marginRight:10}} className="icon" />
                        ทั้งหมด
                    </Button>
                    <Button className={typeSelect === 1 ? "nav-tabs active":"nav-tabs"} onClick={() => onSelectType(1)}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight:10}} className="icon" />
                        ที่ดิน
                    </Button>
                    <Button className={typeSelect === 2 ? "nav-tabs active":"nav-tabs"} onClick={() => onSelectType(2)}>
                        <FontAwesomeIcon icon={faHomeAlt} style={{marginRight:10}} className="icon" />
                        บ้าน
                    </Button>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="default-sm" onClick={toggle}>ค้นหาข้อมูล</Button>
                <Button color="transparent-sm" onClick={toggle}>ยกเลิก</Button>
            </ModalFooter>
        </Modal>

        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        </>
    )
}
export default Map